import { useState } from 'react';
import { MentionsInput, Mention, MentionItem } from 'react-mentions';
import classNames from 'classnames';
import './CommentForm.scss';
import Loader from '../Loader/Loader';
import { searchUser } from '../../core/api';

export interface CommentInput {
  text: string;
  plainText: string;
  mentions: string[];
}

interface IProps {
  className?: string;
  placeholder?: string;
  helperText?: string;
  comment: CommentInput;
  onChange: (comment: CommentInput) => void;
  onSend: () => Promise<any>;
  isProcessing?: boolean;
}

const CommentForm = ({
  onSend,
  placeholder = 'Write a comment...',
  helperText = 'Press ENTER to send',
  comment,
  className,
  isProcessing,
  onChange
}: IProps) => {
  const handleCommentChange = (
    event: any,
    newValue: string,
    plainText: string,
    mentionedUsers: MentionItem[]
  ) => {
    onChange({
      text: event.target.value,
      plainText,
      mentions: mentionedUsers.map((user) => user.id)
    });
  };

  const handleKeyPress = async (
    e: React.KeyboardEvent<HTMLTextAreaElement>
  ) => {
    if (isProcessing) return;

    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      if (comment.text.trim().length) {
        await onSend();
      }
    }
  };

  const fetchUsers = async (query: string, callback: any) => {
    if (!query || query.length < 3) return;

    const users = await searchUser(query);
    const formattedUsers = users.map((user) => ({
      id: user.id,
      display: user.userName
    }));
    callback(formattedUsers);
  };

  return (
    <div className={classNames(`CommentForm ${className}`)}>
      <div className='CommentForm__container'>
        <MentionsInput
          className='mentions'
          value={comment.text}
          onChange={handleCommentChange}
          placeholder={placeholder}
          onKeyPress={handleKeyPress}
        >
          <Mention
            className='mentions__mention'
            displayTransform={(id, username) => `@${username}`}
            markup='@[__display__](__id__)'
            trigger='@'
            data={fetchUsers}
          />
        </MentionsInput>
        {isProcessing && (
          <Loader
            className='CommentForm__loader ms-2'
            fixed={false}
            width='32px'
          />
        )}
      </div>
      <div className='CommentForm__helpText'>{helperText}</div>
      {/* <TextField
        className='CommentForm__input'
        value={comment}
        placeholder={placeholder}
        onChange={handleCommentChange}
        onKeyPress={handleKeyPress}
        helperText={helperText}
      /> */}
    </div>
  );
};

export default CommentForm;
