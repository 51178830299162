import { useContext, useEffect, useState } from 'react';
import {
  FacebookShareButton,
  WhatsappShareButton,
  WhatsappIcon,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
  RedditShareButton,
  RedditIcon,
  LinkedinShareButton,
  LinkedinIcon,
  FacebookMessengerShareButton,
  FacebookMessengerIcon,
  EmailShareButton,
  EmailIcon
} from 'react-share';
import Modal from '../Modal';
import Button from '../../Button/Button';
import Loader from '../../Loader/Loader';
import { getClipShortUrl, getExperienceShortUrl } from '../../../core/api';
import './ShareContentModal.scss';
import appToast from '../../../core/toast';
import TextField from '../../TextField/TextField';
import { ShareModalContext } from './useShareModal';
import { URL_COPIED_CLIPBOARD_MESSAGE } from '../../../core/consts';
import api from '../../../core/api/';
import { ShareType } from '../../../core/api/enums';

const urlRegEx =
  /https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&\/=]*)/g;

const ShareContentModal = () => {
  const { isOpen, contentId, url, contentType, closeShareModal } =
    useContext(ShareModalContext);
  const [isLoading, setIsLoading] = useState(true);
  const [shareUrl, setShareUrl] = useState('');

  useEffect(() => {
    if (!isOpen) return;

    if (url) {
      setShareUrl(url);
      setIsLoading(false);
      return;
    }

    (async () => {
      try {
        setIsLoading(true);

        const { link } = await (contentType === 'clip'
          ? getClipShortUrl(contentId)
          : getExperienceShortUrl(contentId));

        setShareUrl(link);
        setIsLoading(false);
      } catch (e: any) {
        closeShareModal();
        appToast.showError('This media was not found.');
      }
    })();
  }, [contentId, isOpen]);

  const onShareWindowCloses = () => {
    sendSharingStatistics();
  };

  const sendSharingStatistics = async () => {
    try {
      await api.internal.sendSharingStatistics({
        entityId: contentId,
        shareType:
          contentType === 'clip' ? ShareType.SingleMediaFile : ShareType.Event
      });
    } catch (e) {}
  };

  if (!isOpen) return null;
  if (isLoading) return <Loader />;

  const _url = shareUrl.match(urlRegEx)[0];
  const textWithout = shareUrl.replace(urlRegEx, '');

  return (
    <Modal isOpened={isOpen} full={false} showCloseButton={false}>
      <div className='ShareContentModal'>
        <div className='ShareContentModal__title'>Share Content</div>

        <div className='ShareContentModal__content'>
          <FacebookShareButton
            url={_url}
            quote={textWithout}
            hashtag={'#experiencehoopla'}
            onShareWindowClose={onShareWindowCloses}
          >
            <FacebookIcon size={48} borderRadius={4} />
          </FacebookShareButton>

          <WhatsappShareButton
            url={_url}
            title={textWithout}
            onShareWindowClose={onShareWindowCloses}
          >
            <WhatsappIcon size={48} borderRadius={4} />
          </WhatsappShareButton>

          <FacebookMessengerShareButton
            appId='5189530574462386'
            url={_url}
            title={textWithout}
            onShareWindowClose={onShareWindowCloses}
          >
            <FacebookMessengerIcon size={48} borderRadius={4} />
          </FacebookMessengerShareButton>

          <TwitterShareButton
            url={_url}
            title={textWithout}
            hashtags={['experiencehoopla']}
            onShareWindowClose={onShareWindowCloses}
          >
            <TwitterIcon size={48} borderRadius={4} />
          </TwitterShareButton>

          <LinkedinShareButton
            url={_url}
            title={textWithout}
            onShareWindowClose={onShareWindowCloses}
          >
            <LinkedinIcon size={48} borderRadius={4} />
          </LinkedinShareButton>

          <RedditShareButton
            url={_url}
            title={textWithout}
            onShareWindowClose={onShareWindowCloses}
          >
            <RedditIcon size={48} borderRadius={4} />
          </RedditShareButton>

          <EmailShareButton subject={textWithout} url={_url} body={textWithout}>
            <EmailIcon size={48} borderRadius={4} />
          </EmailShareButton>
        </div>

        <div className='ShareContentModal__copyToClipboard'>
          <TextField
            className='ShareContentModal__input'
            disabled={true}
            value={_url}
            onChange={() => {}}
          />
          <Button
            className='ShareContentModal__copy'
            variant='action'
            size='sm'
            onClick={() => {
              navigator.clipboard.writeText(_url);
              appToast.showInfo(URL_COPIED_CLIPBOARD_MESSAGE);
            }}
          >
            Copy
          </Button>
        </div>

        <div className='ShareContentModal__actions'>
          <Button
            variant='outline'
            color='primary'
            size='auto'
            onClick={closeShareModal}
          >
            Close
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default ShareContentModal;
