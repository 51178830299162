import { useRef, useState } from 'react';
import classNames from 'classnames';
import DownloadWidget from '../DownloadWidget/DownloadWidget';
import Button from '../Button/Button';
import { ReactComponent as AddIcon } from '../../icons/add-icon.svg';
import { useOnClickOutside } from '../../hooks';
import { useNavigate } from 'react-router-dom';
import { RoutesEnum } from '../../core/enums';

interface IProps {
  className?: string;
  isAuth: boolean;
  onAddEvent: () => void;
  onSignIn: () => void;
  onLogout: () => void;
}

const HeaderNav = ({
  className = '',
  isAuth,
  onAddEvent,
  onSignIn,
  onLogout
}: IProps) => {
  const [showAppStores, setShowAppStores] = useState(false);
  const floatingPanelRef = useRef<HTMLLIElement>(null);
  const navigate = useNavigate();

  const handleToggleDownloadApps = () => {
    setShowAppStores(!showAppStores);
  };

  useOnClickOutside(floatingPanelRef, () => {
    setShowAppStores(false);
  });

  return (
    <nav className={`HeaderNav ${className}`}>
      <ul>
        <li>
          <Button
            className='me-2'
            size='auto'
            onClick={onAddEvent}
            icon={<AddIcon className='HeaderNav__addIcon' />}
          >
            Add Event
          </Button>
        </li>
        <li
          ref={floatingPanelRef}
          className='position-relative HeaderNav__hideOnSmallDevices'
        >
          <Button
            className='me-2'
            size='auto'
            variant='text'
            onClick={handleToggleDownloadApps}
          >
            Download App
          </Button>

          <div
            className={classNames('HeaderNav__floatingPanels', {
              'HeaderNav__floatingPanels--show': showAppStores
            })}
          >
            <DownloadWidget />
          </div>
        </li>
        {/* <li className='HeaderNav__hideOnSmallDevices'>
          <Button
            className='me-2'
            size='auto'
            variant='text'
            onClick={() => navigate(RoutesEnum.About)}
          >
            About Us
          </Button>
        </li> */}
        {isAuth ? (
          <li className='HeaderNav__hideOnSmallDevices'>
            <Button
              className='me-2'
              size='auto'
              variant='text'
              onClick={onLogout}
            >
              Log out
            </Button>
          </li>
        ) : (
          <li className='HeaderNav__hideOnSmallDevices'>
            <Button
              className='me-2'
              size='auto'
              variant='text'
              onClick={onSignIn}
            >
              Sign In
            </Button>
          </li>
        )}
      </ul>
    </nav>
  );
};

export default HeaderNav;
