export enum RoutesEnum {
  Root = '/',
  Error = '/error',
  Error404 = '/error/404',
  EventsManager = '/checkin',
  Categories = '/category/:slug',
  Hashtag = '/tag/:slug',
  Clips = '/clips',
  ClipsForCategory = '/clips/:slug',
  Videos = '/videos/:id',
  Video = '/clip/:videoId',
  VideoComment = '/clip/:videoId/comment/:commentId',
  Experiences = '/experiences',
  NearBy = '/nearby',
  ThisWeek = '/this-week',
  ThisMonth = '/this-month',
  NewEvents = '/new',
  WhatsTrending = '/trending',
  Experience = '/experience/:gigId',
  Messages = '/messages/:chatId',
  Bookings = '/bookings',
  GuestTicketsSignIn = '/findmyticket/signin',
  GuestTickets = '/findmyticket',
  Booking = '/bookings/:bookingId',
  Tickets = '/tickets/:bookingId',
  Book = '/book/:eventId',
  Events = '/events',
  CreateEvent = '/events/create',
  EditEvent = '/events/edit/:eventId',
  EventCheckIn = '/events/checkin',
  EventCheckInWithId = '/events/checkin/:eventId',
  EventManagement = '/events/manage/:eventId',
  EventManagementSection = '/events/manage/:eventId/:section',
  Registration = '/events/manage/:eventId/registration/:section',
  Notifications = '/notifications',
  ResetPassword = '/resetpassword',
  Search = '/search',
  VendorDashboard = '/dashboard',
  SignupClient = '/signup/client',
  SignupVendor = '/signup/vendor',
  LogIn = '/signin',
  Profile = '/:id',
  VendorBookings = '/u/:id/bookings/:bookingId',
  Privacy = '/privacy-policy',
  Terms = '/terms-and-conditions',
  About = '/about-us',
  ExternalIntegrations = '/external-integrations',
  LegalTerms = '/legal_terms',
  ShortUrlRedirect = '/r/:redirectId',
  Invitation = '/invitation',
  EventCollection = '/collection/:collectionId',
  TagEventCollection = '/tags/:tagId'
}

export enum RolesEnum {
  Client,
  Vendor,
  Admin
}

export enum GenderEnum {
  Male,
  Female,
  Other
}

export enum TrackingEvents {
  LandingPage = 'Landing Page',
  HomePage = 'Home Page',
  ProfilePage = 'Profile Page',
  GigsPage = 'Gigs Page',
  MomentsPage = 'Moments Page',
  CategoriesPage = 'Category Page',
  SearchPage = 'Search Page',
  SearchContent = 'Search Content',
  ViewGig = 'View Gig',
  WatchMoment = 'Watch Moment',
  SignIn = 'User Sign In',
  SignUp = 'User Sign Up',
  SignOut = 'User Sign Out',
  LikeMoment = 'Like Moment',
  UnlikeMoment = 'Unlike Moment',
  CommentMoment = 'Comment Moment',
  DownloadiOSApp = 'Download iOS App',
  DownloadAndroidApp = 'Download Android App',
  ViewEvent = 'View Event',
  ViewExperience = 'View Experience',
  WatchClip = 'Watch Clip',
  AddToCart = 'Add to Cart',
  Purchase = 'Purchase'
}

export enum ContentViewingEnum {
  Modal = 'Modal',
  Page = 'Direct Page'
}

export enum ReportReasons {
  HarrasmentOrBullying,
  HarmfulDesinformation,
  HateSpeech,
  ImpersonatingMe,
  NudityOrPornography,
  PrivateInformation,
  SaleOrPromotionOfDrugs,
  SaleOrPromotionOfFirearms,
  SelfHarm,
  ViolenceOrPhysicalAbuseThreat,
  AdminDecline,
  Spam,
  Other
}

export enum EventRepeatPeriods {
  Day,
  Week,
  Month,
  Custom
}

export enum SalesEndTimeInterval {
  Days
}

export enum SalesEndTimeOptions {
  BeforeEventStarts
}

export enum AttendeesLimitOption {
  Unlimited,
  Limited
}

export enum ProfileTabsEnum {
  Upcoming,
  Past,
  Cancelled
}
