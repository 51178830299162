import { makeAutoObservable } from 'mobx';
import { BannerViewModel } from '../core/backend/models';
import ExperienceModel from '../core/models/ExperienceModel';
import { CategoryExperiences, IVideoModel } from '../core/types';
import { BannerType } from '../core/api/enums';
import { GetHighlightBannersOutput } from '../core/api/models';
import { isValidURL } from '../core/helpers';

interface DiscoveryBanner {
  imageUrl: string;
  title?: string;
  subtitle?: string;
  link?: string;
  hasLink?: boolean;
}

interface DiscoveryBanners {
  hero: DiscoveryBanner;
  secondary: DiscoveryBanner[] | null;
}

class DiscoveryStore {
  fetched: boolean = false;
  banners: DiscoveryBanners = {
    hero: null,
    secondary: null
  };
  highlightBanners: GetHighlightBannersOutput[] = [];
  nearbyEvents: ExperienceModel[] | undefined = [];
  thisWeekEvents: ExperienceModel[] | undefined = [];
  thisMonthEvents: ExperienceModel[] | undefined = [];
  newEvents: ExperienceModel[] | undefined = [];
  whatsTrendingEvents: ExperienceModel[] | undefined = [];
  popularCategoryEvents: CategoryExperiences[] | undefined = [];
  randomEvents: ExperienceModel[] | undefined = [];
  clips: IVideoModel[] | undefined = [];

  constructor() {
    makeAutoObservable(this);
  }

  saveAll = (
    nearbyEvents: ExperienceModel[] | undefined,
    thisWeekEvents: ExperienceModel[] | undefined,
    thisMonthEvents: ExperienceModel[] | undefined,
    newEvents: ExperienceModel[] | undefined,
    whatsTrendingEvents: ExperienceModel[] | undefined,
    popularCategoryEvents: CategoryExperiences[] | undefined,
    randomEvents: ExperienceModel[] | undefined,
    clips: IVideoModel[] | undefined
  ) => {
    this.nearbyEvents = nearbyEvents;
    this.thisWeekEvents = thisWeekEvents;
    this.thisMonthEvents = thisMonthEvents;
    this.newEvents = newEvents;
    this.whatsTrendingEvents = whatsTrendingEvents;
    this.popularCategoryEvents = popularCategoryEvents;
    this.randomEvents = randomEvents;
    this.clips = clips;
    this.fetched = true;
  };

  saveBanners = (banners: BannerViewModel[] = []) => {
    const hero = banners?.find(
      (banner) => banner.bannerType === BannerType.HeroBanner
    );

    const secondaryBanners = banners?.filter(
      (banner) => banner.bannerType === BannerType.SecondaryBanner
    );

    this.banners = {
      hero: {
        imageUrl: hero?.mediaFile?.awsUrl,
        title: hero?.title,
        subtitle: hero?.subtitle
      },
      secondary: secondaryBanners?.map<DiscoveryBanner>((banner) => ({
        imageUrl: banner.mediaFile.awsUrl,
        title: banner.title,
        subtitle: banner.subtitle,
        link: banner.link,
        hasLink: isValidURL.test(banner.link)
      }))
    };
  };

  saveHighlightBanners = (banners: GetHighlightBannersOutput[] = []) => {
    this.highlightBanners = banners;
  };

  saveNearbyEvents = (events: ExperienceModel[] | undefined) => {
    this.nearbyEvents = events;
  };

  saveThisWeekEvents = (events: ExperienceModel[] | undefined) => {
    this.thisWeekEvents = events;
  };

  saveThisMonthEvents = (events: ExperienceModel[] | undefined) => {
    this.thisMonthEvents = events;
  };

  saveNewEvents = (events: ExperienceModel[] | undefined) => {
    this.newEvents = events;
  };

  saveWhatsTrendingEvents = (events: ExperienceModel[] | undefined) => {
    this.whatsTrendingEvents = events;
  };

  savePopularCategoryEvents = (events: CategoryExperiences[] | undefined) => {
    this.popularCategoryEvents = events;
  };

  saveRandomEvents = (events: ExperienceModel[] | undefined) => {
    this.randomEvents = events;
  };

  saveClips = (clips: IVideoModel[] | undefined) => {
    this.clips = clips;
  };
}

export default DiscoveryStore;
