import axios, { AxiosResponse } from 'axios';
import {
  CreatedFileViewModel,
  FileResponseViewModel,
  CommentViewModel,
  CategoryViewModel,
  GigViewModel,
  UserViewModel,
  ReviewViewModel,
  BannerViewModel,
  HashtagViewModel,
  PaymentMethodViewModel,
  BookingViewModel,
  ProviderSummaryResponse,
  MasterSearchResultsViewModel,
  ChatViewModel,
  ResourceLinkViewModel,
  UserFollowingViewModel,
  AppPromotionCodeViewModel,
  LikeViewModel,
  GetGigVideosByCategoryViewModel,
  GroupedFileViewModel,
  AttendeeViewModel,
  CommentViewModelV21,
  UserMinimumViewModel,
  BookingQrDataViewModel,
  RecurrenceViewModel,
  FollowerViewModel,
  BookingAttendeesResponse,
  GetRegisteredListViewModel,
  GetCompanyUserViewModel,
  GetAttendeesListViewModel,
  GetByEventIdViewModel,
  GetEventAvailableTicketStatsOutput,
  InviteViewModel,
  GigSuggestionViewModel,
  GigInvitationCodeViewModel,
  GetGuestUsersViewModel,
  GigSuggestionGigViewModel,
  RecurrenceGigOptionViewModel
} from './backend/models';
import {
  AppleAuthRequestModel,
  CommentMediaFileRequestModel,
  CreateBookingRequestModel,
  DailyRecurrenceInput,
  FacebookLoginRequest,
  GetGigVideosByCategoryList,
  MonthDayRecurrenceInput,
  MonthlyRecurrenceInput,
  RecurrenceDateTimeRequest,
  RecurrenceGigOptionRequest,
  UpdateUserProfileRequest,
  WeekDayRecurrenceInput,
  WeeklyRecurrenceInput
} from './backend/interfaces';
import getGlobal from './globals';
import { ICoordinates, NodaResponse } from './types';
import { IntegratedMeeting } from './backend/interfaces';
import { VirtualMeetingType } from './backend/enums';
import UserQrDataViewModel from './backend/models/user-qr-data-view-model';
import GigQrDataViewModel from './backend/models/gig-qr-data-view-model';
import { EventResponse } from './api/models';

const _axios = axios.create({
  withCredentials: true,
  baseURL: getGlobal('api')
});

export let requestsCancelToken = axios.CancelToken.source();

//cancel axios pending requests helpers
export const refreshCancelToken = () => {
  requestsCancelToken = axios.CancelToken.source();
};

export const finishPendingRequests = () => {
  requestsCancelToken.cancel('Changing page');
  refreshCancelToken();
};

/// API Starts
export async function uploadPublicFile(file: File) {
  const formData = new FormData();
  formData.append('file', file);
  const response: AxiosResponse<CreatedFileViewModel> = await _axios.post(
    `/PublicFile/UploadPublicFile`,
    formData,
    {
      headers: {
        'content-type': 'multipart/form-data'
      }
    }
  );

  return response.data;
}

export async function getInviteListTemplate(eventId: number) {
  const response: AxiosResponse<Blob> = await _axios.get(
    `/Invites/DownloadTemplateUploadInviteList/${eventId}`,
    {
      responseType: 'blob'
    }
  );

  return response.data;
}

export async function uploadExcelDoc(eventId: number, file: File) {
  const formData = new FormData();
  formData.append('GigId', eventId.toString());
  formData.append('File', file);

  const response: AxiosResponse = await _axios.post(
    `/Invites/UploadInviteList`,
    formData,
    {
      headers: {
        'content-type': 'multipart/form-data'
      }
    }
  );

  return response.data;
}

export function getPublicFile(fileName: string) {
  if (!fileName) return;
  return `/PublicFile/DownloadPublicFile?filename=${fileName}`;
}

// https://hoopla-bucket.s3.us-west-2.amazonaws.com/
export function getMediaFile(filename: string) {
  return `https://d1p2nq1n6y6s4p.cloudfront.net/${filename}`;
}

export async function uploadUserImage(file: File) {
  const formData = new FormData();
  formData.append('file', file);
  const response: AxiosResponse<UserViewModel> = await _axios.put(
    `/Accounts/UserImage`,
    formData,
    {
      headers: {
        'content-type': 'multipart/form-data'
      }
    }
  );

  return response.data;
}

export async function signIn(email: string, password?: string) {
  const response: AxiosResponse = await _axios.post(
    `/Auth/LogIn`,
    {
      email,
      password
    },
    {
      headers: {
        'content-type': 'application/json',
        ...getGlobal('devHeader')
      }
    }
  );

  return response.data;
}

export async function signInWithGoogle(idToken: string) {
  const isTesting = getGlobal('env') !== 'production';

  const response: AxiosResponse = await _axios.post(
    `/Auth/GoogleUser?idToken=${idToken}&isTesting=${isTesting}`,
    {
      headers: {
        ...getGlobal('devHeader')
      }
    }
  );

  return response.data;
}

export async function signInWithApple(appleAuthPayload: AppleAuthRequestModel) {
  const payload = {
    ...appleAuthPayload,
    isTesting: getGlobal('env') !== 'production'
  };

  const response: AxiosResponse = await _axios.post(
    `/Auth/AppleUser`,
    payload,
    {
      headers: {
        ...getGlobal('devHeader')
      }
    }
  );

  return response.data;
}

// TODO: Remove this endpoint
// export async function signInWithFacebook(
//   facebookAuthPayload: FacebookLoginRequest
// ) {
//   const response: AxiosResponse = await _axios.post(
//     `/Auth/FacebookUser`,
//     facebookAuthPayload,
//     {
//       headers: {
//         ...getGlobal('devHeader')
//       }
//     }
//   );
//
//   return response.data;
// }

export async function requestPhoneSMSCode(phoneNumber: string) {
  const response: AxiosResponse = await _axios.post(
    `/Auth/SmsLogin/RequestNew?phoneNumber=${phoneNumber}`
  );
  return response.data;
}

export async function signInWithPhoneSMSCode(code: string) {
  const response: AxiosResponse = await _axios.post(
    `/Auth/SmsLogin/Authenticate?code=${code}`,
    {
      headers: {
        ...getGlobal('devHeader')
      }
    }
  );
  return response.data;
}

export async function logOut() {
  const response = await _axios.post(`/Auth/LogOut`, {
    headers: {
      ...getGlobal('devHeader')
    }
  });

  return response.data;
}

export async function signUpUserWithEmailAndPassword(
  email: string,
  password: string
) {
  const response = await _axios.post(`/Accounts/RegisterByEmailAndPassword`, {
    email,
    password
  });

  return response.data;
}

export async function completeRegistration(
  userName: string,
  fullName: string,
  phoneNumber: string,
  dateOfBirthSeconds: number
) {
  const response: AxiosResponse<UserViewModel> = await _axios.put(
    `/Accounts/CompleteRegistration?userName=${userName}&fullName=${fullName}&dateOfBirthSeconds=${dateOfBirthSeconds}&phoneNumber=${phoneNumber}`
  );

  return response.data;
}

export async function registerGuestUserData(
  email: string,
  password: string,
  userName: string,
  fullName: string,
  phoneNumber: string,
  dateOfBirthSeconds: number
) {
  const response = await _axios.post(`/Accounts/RegisterByCheckout`, {
    email,
    password,
    userName,
    fullName,
    phoneNumber,
    dateOfBirthSeconds
  });

  return response.data;
}

export async function resetUserPassword(email: string) {
  const response: AxiosResponse = await _axios.post(
    '/Auth/GetPasswordResetToken',
    { email }
  );

  return response.data;
}

export async function changeUserPassword(
  email: string,
  newPassword: string,
  token: string
) {
  const response: AxiosResponse<String> = await _axios.post(
    '/Auth/ChangePasswordWithResetToken',
    {
      email,
      token,
      newPassword
    }
  );

  return response.data;
}

export async function getUser() {
  const response: AxiosResponse<UserViewModel> = await _axios.get(
    `/Accounts/Info/Own`
  );

  return response.data;
}

export async function updateUser(
  userFields: Partial<UpdateUserProfileRequest>
) {
  const response: AxiosResponse<UserViewModel> = await _axios.put(
    `/Accounts/UpdateUserProfile`,
    userFields
  );

  return response.data;
}

export async function deleteActiveUserAccount() {
  const response: AxiosResponse = await _axios.delete(`/Accounts`);
  return response.data;
}

export async function searchUser(query: string) {
  const response: AxiosResponse<UserViewModel[]> = await _axios.get(
    `/Search/SearchVendors?query=${query}`
  );

  return response.data;
}

export async function getReverseGeolocation(lat: number, lng: number) {
  const response: AxiosResponse<any> = await _axios.get(
    `/Geolocation/GetReverseGeolocation?lat=${lat}&lng=${lng}`
  );
  return response.data;
}

export async function getCategories() {
  const res: AxiosResponse<CategoryViewModel[]> = await _axios.get(
    `/Categories/NoAuthentication`
  );
  return res.data;
}

export async function getTopCategories() {
  const res: AxiosResponse<CategoryViewModel[]> = await _axios.get(
    `/Categories/GetTopCategoriesNoAuth`
  );
  return res.data;
}

//Gigs
export async function getGigs(startAt?: number, number?: number) {
  const paging =
    startAt !== undefined && number !== undefined
      ? `?skip=${startAt}&take=${number}`
      : '';
  const response: AxiosResponse<GigViewModel[]> = await _axios.get(
    `/Gigs/NoAuthentication${paging}`
  );
  return response.data;
}

//TODO: Remove this endpoint
export async function getEvents(skip?: number, take?: number) {
  const response: AxiosResponse<GigViewModel[]> = await _axios.get(
    `/Gigs/GetGigsEventOnly/NoAuthentication?skip=${skip}&take=${take}`
  );
  return response.data;
}

export async function getGigsByCategory(
  categoryId: number,
  startAt?: number,
  number?: number
) {
  const paging =
    startAt !== undefined && number !== undefined
      ? `&skip=${startAt}&take=${number}`
      : '';
  const response: AxiosResponse<GigViewModel[]> = await _axios.get(
    `/Gigs/ByCategory/NoAuthentication?categoryId=${categoryId}${paging}`
  );

  return response.data;
}

export async function getPopularExperiences(skip?: number, take?: number) {
  const paging =
    skip !== undefined && take !== undefined
      ? `?skip=${skip}&take=${take}`
      : '';
  const response: AxiosResponse<GigViewModel[]> = await _axios.get(
    `/Gigs/NoAuthentication${paging}`
  );

  return response.data;
}

export async function getLastEventIdFromUser(userGenericName: string) {
  const response: AxiosResponse<string> = await _axios.get(
    `/Gigs/GetCustomLastGigUrl?generic=${userGenericName}`
  );

  return response.data;
}

export async function getRelatedMemories(
  gigId: number,
  startAt?: number,
  number?: number
) {
  const paging =
    startAt !== undefined && number !== undefined
      ? `&skip=${startAt}&take=${number}`
      : '';
  const response: AxiosResponse<GroupedFileViewModel[]> = await _axios.get(
    `/Content/Grouped/GetStoriesForGigId?gigId=${gigId}${paging}`
  );

  return response.data;
}

export async function getEventClips(
  gigId: number,
  skip?: number,
  take?: number
) {
  const response: AxiosResponse<GroupedFileViewModel[]> = await _axios.get(
    'Content/Grouped/GetStoriesForGigId',
    {
      params: {
        gigId,
        skip,
        take
      }
    }
  );

  return response.data;
}

//TODO: Remove this endpoint
export async function getLatestExperiences(skip?: number, take?: number) {
  const response: AxiosResponse<GigViewModel[]> = await _axios.get(
    `/Gigs/NoAuthentication?skip=${skip}&take=${take}`
  );

  return response.data;
}

export async function getGigsByUserNoAuth(
  userId: string,
  startAt?: number,
  number?: number,
  query?: string
) {
  const paging =
    startAt !== undefined && number !== undefined
      ? `&skip=${startAt}&take=${number}`
      : '';
  const response: AxiosResponse<GigViewModel[]> = await _axios.get(
    `/Gigs/ByUser/NoAuthentication?userId=${userId}${paging}${
      query ? `&query=${query}` : ''
    }`
  );

  return response.data;
}

export async function getGigsByUser(
  userId: string,
  skip?: number,
  take?: number,
  query?: string
) {
  const paging =
    skip !== undefined && take !== undefined
      ? `&skip=${skip}&take=${take}`
      : '';
  const response: AxiosResponse<GigViewModel[]> = await _axios.get(
    `/Gigs?userId=${userId}${paging}${query ? `&query=${query}` : ''}`
  );

  return response.data;
}

export async function getEventById(gigId: number) {
  const response: AxiosResponse<GigViewModel> = await _axios.get(
    `/Gigs/${gigId}`
  );

  return response.data;
}

export async function getGigByIdAuth(eventId: number) {
  const response: AxiosResponse<GigViewModel> = await _axios.get(
    `/Gigs/${eventId}`
  );

  return response.data;
}

export async function getGigById(gigId: number) {
  const response: AxiosResponse<GigViewModel> = await _axios.get(
    `/Gigs/GetGigByIdNoAuth?id=${gigId}`
  );

  return response.data;
}

export async function getEventSuggestions() {
  const response: AxiosResponse<GigSuggestionViewModel[]> = await _axios.get(
    '/Gigs/Suggestions'
  );

  return response.data;
}

export async function getEventSuggestionsAI() {
  const response: AxiosResponse<GigSuggestionGigViewModel[]> = await _axios.get(
    '/Gigs/Suggestions/AI'
  );

  return response.data;
}

export async function getExperienceAttendees(experienceId: number) {
  const response: AxiosResponse<AttendeeViewModel[]> = await _axios.get(
    `/Bookings/Attendances/GetAttendances/NoAuth?experienceId=${experienceId}`
  );
  return response.data;
}

export async function getEventAteendeeData(
  gigId: number,
  skip?: number,
  take?: number,
  companyUserId?: string,
  searchQuery?: string
) {
  const response: AxiosResponse<GetAttendeesListViewModel> = await _axios.get(
    'GigInvitationCode/GetAttendeeList',
    { params: { gigId, skip, take, searchQuery, companyUserId } }
  );

  return response.data;
}

export async function getRegisterListData(
  gigId: number,
  skip?: number,
  take?: number,
  companyUserId?: string,
  searchQuery?: string
) {
  const response: AxiosResponse<GetRegisteredListViewModel> = await _axios.get(
    '/GigInvitationCode/GetRegisteredList',
    { params: { gigId, skip, take, searchQuery, companyUserId } }
  );

  return response.data;
}

export async function getInviteeListData(
  eventId: number,
  skip?: number,
  take?: number,
  companyUserId?: string,
  searchQuery?: string
) {
  const response: AxiosResponse<GetByEventIdViewModel> = await _axios.get(
    '/Invites/GetByEvent',
    { params: { skip, take, searchQuery, gigId: eventId, companyUserId } }
  );

  return response.data;
}

export async function getGuestUserList(eventId: number, searchQuery?: string) {
  const response: AxiosResponse<GetGuestUsersViewModel[]> = await _axios.get(
    '/Invites/GetGuestUsers',
    { params: { searchQuery, gigId: eventId } }
  );

  return response.data;
}

export async function deleteGuestUser(eventId: number, inviteId: number) {
  const response: AxiosResponse = await _axios.delete(
    `Invites/DeleteGuest?inviteId=${inviteId}&gigId=${eventId}`
  );

  return response.data;
}

export async function addInvitations(
  gigId: number,
  gigInvitationCodeId: number,
  guests: any[]
) {
  const response: AxiosResponse<GetByEventIdViewModel> = await _axios.post(
    '/Invites/CreateInvites',
    {
      gigId,
      gigInvitationCodeId,
      recurrenceId: 0,
      guests
    }
  );

  return response.data;
}

export async function addGuestInvitations(gigId: number, guests: any[]) {
  const response: AxiosResponse<GetByEventIdViewModel> = await _axios.post(
    '/Invites/InviteGuests',
    {
      gigId,
      guests
    }
  );

  return response.data;
}

export async function getAllInvitationCodes(gigId: number) {
  const response: AxiosResponse<GigInvitationCodeViewModel[]> =
    await _axios.get('/GigInvitationCode/GetAll', {
      params: { gigId }
    });

  return response.data;
}

export async function getEventCompanies(gigId: number) {
  const response: AxiosResponse<GetCompanyUserViewModel[]> = await _axios.get(
    '/GigInvitationCode/GetCompanyUsers',
    {
      params: { gigId }
    }
  );

  return response.data;
}

export async function getGrammyEventId() {
  const response: AxiosResponse<number> = await _axios.get(
    '/GigInvitationCode/GetGrammyEventId'
  );

  return response.data;
}

export async function approveRegister(ids: Array<number>) {
  const response: AxiosResponse<GetEventAvailableTicketStatsOutput[]> =
    await _axios.put('/Invites/Provider/Bulk/Accept', ids);

  return response;
}

export async function rejectRegister(ids: Array<number>) {
  const response: AxiosResponse<GetEventAvailableTicketStatsOutput[]> =
    await _axios.put('/Invites/Provider/Bulk/Reject', ids);

  return response;
}

export async function getInviteesExcelInformation(gigId: number) {
  const response: AxiosResponse<Blob> = await _axios.get(
    '/Invites/GetExcelInvitesList',
    {
      params: { gigId },
      responseType: 'blob'
    }
  );

  return response.data;
}

export async function getExcelRegisterInformation(gigId: number) {
  const response: AxiosResponse<Blob> = await _axios.get(
    '/GigInvitationCode/GetExcelRegisteredList',
    {
      params: { gigId },
      responseType: 'blob'
    }
  );

  return response.data;
}

export async function getAttendeesExcelInformation(eventId: number) {
  const response: AxiosResponse<Blob> = await _axios.get(
    '/Bookings/GetExcelEventInformation',
    {
      params: { eventId },
      responseType: 'blob'
    }
  );

  return response.data;
}

export async function validateInvitationCode(
  gigId: number,
  code: string,
  email?: string
) {
  const response: AxiosResponse<InviteViewModel> = await _axios.get(
    '/GigInvitationCode/FNF26Validate',
    {
      params: { gigId, code, email }
    }
  );

  return response.data;
}

export async function getEventTicketStats(
  eventId: number,
  recurrenceId: number
) {
  const recurrenceParam = recurrenceId ? `&recurrenceId=${recurrenceId}` : '';

  const response: AxiosResponse<GetEventAvailableTicketStatsOutput[]> =
    await _axios.get(
      `/Gigs/GetEventAvailableTicketStats?eventId=${eventId}${recurrenceParam}`
    );

  return response.data;
}

export async function getEventRecurrences(
  eventId: number,
  excludeInactive?: boolean
) {
  const response: AxiosResponse<RecurrenceViewModel[]> = await _axios.get(
    '/Recurrences/GetEventRecurrences',
    {
      params: {
        eventId,
        excludeInactive
      }
    }
  );

  return response.data;
}

export async function getEventRecurrencesAuth(eventId: number) {
  const response: AxiosResponse<RecurrenceViewModel[]> = await _axios.get(
    '/Recurrences/GetEventRecurrences/Auth',
    {
      params: {
        eventId
      }
    }
  );

  return response.data;
}

export async function updateEventRecurrenceData(
  payload: RecurrenceGigOptionRequest
) {
  const response: AxiosResponse<RecurrenceGigOptionViewModel> =
    await _axios.put('/Recurrences/UpdateRecurrenceEventData', payload);

  return response.data;
}

export async function updateEventRecurrenceDateTime(
  payload: RecurrenceDateTimeRequest
) {
  const params = Object.entries(payload).reduce((acc, tuple, idx) => {
    return acc + `${idx > 0 ? '&' : ''}${tuple[0]}=${tuple[1]}`;
  }, '?');

  const response: AxiosResponse<RecurrenceViewModel> = await _axios.put(
    '/Recurrences/UpdateRecurrenceStartDatetime' + params
  );

  return response.data;
}

export async function getGigReviews(
  gigId: number,
  startAt?: number,
  number?: number
) {
  const paging =
    startAt !== undefined && number !== undefined
      ? `&skip=${startAt}&take=${number}`
      : '';
  const response: AxiosResponse<ReviewViewModel[]> = await _axios.get(
    `/Reviews/NoAuthentication?gigId=${gigId}${paging}`
  );

  return response.data;
}

//Videos
export async function getVideos(startAt?: number, number?: number) {
  const paging =
    startAt !== undefined && number !== undefined
      ? `?skip=${startAt}&take=${number}`
      : '';

  const response: AxiosResponse<FileResponseViewModel[]> = await _axios.get(
    `/Content/Videos/NoAuthentication${paging}`
  );

  return response.data;
}

export async function getVideosByCategory(
  categoryId: number,
  startAt?: number,
  number?: number
) {
  const paging =
    startAt !== undefined && number !== undefined
      ? `&skip=${startAt}&take=${number}`
      : '';
  const response: AxiosResponse<FileResponseViewModel[]> = await _axios.get(
    `/Content/Videos/ByCategory/NoAuthentication?categoryId=${categoryId}${paging}`
  );

  return response.data;
}

export async function getVideosByUser(
  userId: string,
  startAt?: number,
  number?: number
) {
  const paging =
    startAt !== undefined && number !== undefined
      ? `&skip=${startAt}&take=${number}`
      : '';
  const response: AxiosResponse<FileResponseViewModel[]> = await _axios.get(
    `/Content/Videos/ByUser/NoAuthentication?userId=${userId}${paging}`
  );

  return response.data;
}

export async function getMoments(startAt?: number, number?: number) {
  const paging =
    startAt !== undefined && number !== undefined
      ? `?skip=${startAt}&take=${number}`
      : '';

  const response: AxiosResponse<GroupedFileViewModel[]> = await _axios.get(
    `/Content/Grouped/GetRecentStoriesNoAuth${paging}`
  );

  return response.data;
}

export async function getMemoriesByUser(
  userId: string,
  startAt?: number,
  number?: number
) {
  const paging =
    startAt !== undefined && number !== undefined
      ? `&skip=${startAt}&take=${number}`
      : '';
  const response: AxiosResponse<GroupedFileViewModel[]> = await _axios.get(
    `/Content/Grouped/GetStoriesFromUserIdNoAuth?userId=${userId}${paging}`
  );

  return response.data;
}

export async function getMomentsByCategory(
  categoryId: number,
  startAt?: number,
  number?: number
) {
  const paging =
    startAt !== undefined && number !== undefined
      ? `&skip=${startAt}&take=${number}`
      : '';
  const response: AxiosResponse<GroupedFileViewModel[]> = await _axios.get(
    `/Content/Grouped/GetStoriesByCategoryIdNoAuth?categoryId=${categoryId}${paging}`
  );

  return response.data;
}

export async function getVideoById(mediaFileId: number) {
  const response: AxiosResponse<FileResponseViewModel> = await _axios.get(
    `/MediaFiles/GetMediaFileById?mediaFileId=${mediaFileId}`
  );

  return response.data;
}

export async function getVideoCommentsNoAuth(
  fileId: number,
  startAt?: number,
  number?: number
) {
  const paging =
    startAt !== undefined && number !== undefined
      ? `&skip=${startAt}&take=${number}`
      : '';
  const response: AxiosResponse<CommentViewModel[]> = await _axios.get(
    `/ContentInteraction/MediaFileComments/NoAuthentication?mediaFileId=${fileId}${paging}`
  );

  return response.data;
}

export async function getVideoComments(
  fileId: number,
  startAt?: number,
  number?: number
) {
  const paging =
    startAt !== undefined && number !== undefined
      ? `&skip=${startAt}&take=${number}`
      : '';
  const response: AxiosResponse<CommentViewModelV21[]> = await _axios.get(
    `/ContentInteraction/GetMediaFileComments?mediaFileId=${fileId}${paging}`
  );

  return response.data;
}

//Creators
export async function getCreators() {
  const response: AxiosResponse<UserViewModel[]> = await _axios.get(
    `/Roles/GetAllPopularCreatorPROs`
  );

  return response.data;
}

export async function getCreatorById(userId: string) {
  const response: AxiosResponse<UserViewModel> = await _axios.get(
    `/Users/ById/NoAuthentication?userId=${userId}`
  );

  return response.data;
}

export async function getUserByUserName(userName: string) {
  const response: AxiosResponse<UserViewModel> = await _axios.get(
    `/Users/GetUserByUserName?username=${userName}`
  );

  return response.data;
}

export async function getProfileQRCode(userId: string) {
  const response: AxiosResponse<UserQrDataViewModel> = await _axios.get(
    `/Qr/User/QrData?userId=${userId}`
  );

  return response.data;
}

export async function getEventQRCode(gigId: number) {
  const response: AxiosResponse<GigQrDataViewModel> = await _axios.get(
    `/Qr/Gig/QrData?gigId=${gigId}`
  );

  return response.data;
}

//Content
export async function getSharedVideo(sharedId: string) {
  const response: AxiosResponse<FileResponseViewModel> = await _axios.get(
    `/Content?shareId=${sharedId}`
  );
  return response.data;
}

//Banners
export async function getBanners() {
  const response: AxiosResponse<BannerViewModel[]> = await _axios.get(
    `/Banners/GetAllBannersNoAuth`
  );

  return response.data;
}

//Deep Linking
export async function getGigDeepLink(gigId: number) {
  const response: AxiosResponse<string> = await _axios.get(
    `/DeepLink/GetGigDeepLinkNoAuth?gigId=${gigId}`
  );

  return response.data;
}

export async function getVideoDeepLink(mediaFileId: number) {
  const response: AxiosResponse<string> = await _axios.get(
    `/DeepLink/GetMediaFileDeepLinkNoAuth?mediaFileId=${mediaFileId}`
  );

  return response.data;
}

export async function getBookingDeepLink(bookingId: number) {
  const response: AxiosResponse<string> = await _axios.get(
    `/DeepLink/GetBookingDeepLinkNoAuth?bookingId=${bookingId}`
  );

  return response.data;
}

export async function getChatDeepLink(chatId: string) {
  const response: AxiosResponse<string> = await _axios.get(
    `/DeepLink/GetChatDeepLinkNoAuth?chatId=${chatId}`
  );

  return response.data;
}

export async function getCommentDeepLink(
  mediaFileId: number,
  commentId: number
) {
  const response: AxiosResponse<string> = await _axios.get(
    `/DeepLink/GetCommentDeepLinkNoAuth?mediaFileId=${mediaFileId}&commentId=${commentId}`
  );

  return response.data;
}

export async function getUserDeepLink(userId: string) {
  const response: AxiosResponse<string> = await _axios.get(
    `/DeepLink/GetUserDeepLinkNoAuth?userId=${userId}&forceDeepLink=true`
  );

  return response.data;
}

export async function getResetPasswordDeepLink(token: string) {
  const response: AxiosResponse<string> = await _axios.get(
    `/DeepLink/GetResetPasswordDeepLink?token=${token}`
  );

  return response.data;
}

//Search endpoints
export async function searchActiveEvents(query: string) {
  const response: AxiosResponse<GigViewModel[]> = await _axios.get(
    '/Search/SearchActiveEvents',
    {
      params: { query, skip: 0, take: 16 }
    }
  );

  return response.data;
}

export async function searchVideos(query: string) {
  const response: AxiosResponse<FileResponseViewModel[]> = await _axios.get(
    `/Search/SearchVideos?query=${query}`
  );

  return response.data;
}

export async function searchHashtags(query: string) {
  const response: AxiosResponse<HashtagViewModel[]> = await _axios.get(
    `/Search/SearchHashtags?query=${query}`
  );

  return response.data;
}

export async function getNearbyExperiences({ lat, lng }: ICoordinates) {
  const response: AxiosResponse<GigViewModel[]> = await _axios.get(
    `/Gigs/NearbyNoAuthentication?&lat=${lat}&lng=${lng}`
  );

  return response.data;
}

export async function masterSearch(query: string, { lat, lng }: ICoordinates) {
  const queryParams = lat && lng ? `&lat=${lat}&lng=${lng}` : '';

  const response: AxiosResponse<MasterSearchResultsViewModel> =
    await _axios.get(
      `/Search/Grouped/MasterSearch?query=${query}${queryParams}`
    );

  return response.data;
}

export async function getRedirectUrlFromGenericId(genericId: string) {
  const response: AxiosResponse = await _axios.get(`/r/${genericId}`);

  return response.data;
}

export async function getCreatorStats(userId: string) {
  const response: AxiosResponse<ProviderSummaryResponse> = await _axios.get(
    `/UserStatistics/GetProviderSummary?userId=${userId}`
  );

  return response.data;
}

export async function getUserFollowers(userId: string) {
  const response: AxiosResponse<FollowerViewModel[]> = await _axios.get(
    `/Follow/GetUserFollowers?userId=${userId}`
  );
  return response.data;
}

//Authenticated EndPoints

export async function commentMediaFile(data: CommentMediaFileRequestModel) {
  const response: AxiosResponse<CommentViewModel> = await _axios.post(
    `/ContentInteraction/CommentMediaFile`,
    data
  );

  return response.data;
}

export async function likeVideo(mediaFileId: number) {
  const response: AxiosResponse = await _axios.get(
    `/ContentInteraction/LikeUnlikeContentMediaFile?mediaFileId=${mediaFileId}`
  );

  return response.data;
}

export async function likeComment(commentId: number) {
  const response: AxiosResponse = await _axios.get(
    `/ContentInteraction/LikeUnlikeComment?commentId=${commentId}`
  );

  return response.data;
}

export async function getOwnLikes() {
  const response: AxiosResponse<LikeViewModel[]> = await _axios.get(
    `/Activity/GetOwnLikes`
  );

  return response.data;
}

export async function AddOneViewToVideo(videoId: number) {
  const response = await _axios.get(
    `/ContentInteraction/AddOneViewForVideo?videoId=${videoId}`
  );

  return response.data;
}

export async function getUserBookings() {
  const response: AxiosResponse<BookingViewModel[]> = await _axios.get(
    `/Bookings/GetAllBookingsForOwnUser`
  );

  return response.data;
}

export async function getCreatorBookings() {
  const response: AxiosResponse<BookingViewModel[]> = await _axios.get(
    `/Bookings/GetAllJobsForOwnUser`
  );

  return response.data;
}

export async function bookExperience(
  bookingRequest: CreateBookingRequestModel
) {
  const response: AxiosResponse<BookingViewModel> = await _axios.post(
    `/Bookings/CreateAppointmentAndAuthorizeBookingPayment`,
    bookingRequest
  );

  return response.data;
}

export async function addClicksToExternalEvent(gigId: number) {
  const response: AxiosResponse = await _axios.post(
    `/Gigs/AddGigTicketClick?gigId=${gigId}`
  );

  return response.data;
}

export async function getUserPaymentMethods() {
  const response: AxiosResponse<PaymentMethodViewModel[]> = await _axios.get(
    `/Stripe/PaymentMethods/List/Customer`
  );

  return response.data;
}

export async function updateDefaultPaymentMethod(paymentMethodId: string) {
  const response: AxiosResponse<BookingViewModel> = await _axios.put(
    `/Stripe/SetCustomerDefaultPaymentMethod?paymentMethodId=${paymentMethodId}`
  );

  return response.data;
}

export async function deletePaymentMethod(paymentMethodId: string) {
  const response: AxiosResponse<BookingViewModel> = await _axios.delete(
    `/Stripe/RemoveCustomerPaymentMethod?paymentMethodId=${paymentMethodId}`
  );

  return response.data;
}

export async function getStripePublicKey() {
  const response: AxiosResponse<{ publishableKey: string }> = await _axios.get(
    `/Stripe/GetPublishableKey`
  );

  return response.data;
}

export async function getStripeClientSecretKey() {
  const response: AxiosResponse<{ client_secret: string }> = await _axios.get(
    `/Stripe/PaymentMethods/SetupPaymentMethod`
  );

  return response.data;
}

export async function createDirectChat(userId: string) {
  const response: AxiosResponse<ChatViewModel> = await _axios.get(
    `/Messenger/CreateDirectChat?userId=${userId}`
  );

  return response.data;
}

export async function getExperienceShortUrl(gigId: number) {
  const response: AxiosResponse<ResourceLinkViewModel> = await _axios.get(
    `/ContentInteraction/GigSharingLink?gigId=${gigId}`
  );

  return response.data;
}

export async function getClipShortUrl(mediaFileId: number) {
  const response: AxiosResponse<ResourceLinkViewModel> = await _axios.get(
    `/ContentInteraction/ClipSharingLink?mediaFileId=${mediaFileId}`
  );

  return response.data;
}

//Follow users
export async function followUser(userId: string) {
  const response: AxiosResponse<UserFollowingViewModel> = await _axios.get(
    `/Follow/FollowUser?followedUserId=${userId}`
  );

  return response.data;
}

export async function unfollowUser(userId: string) {
  const response: AxiosResponse<UserFollowingViewModel> = await _axios.get(
    `/Follow/UnfollowUser?followedUserId=${userId}`
  );

  return response.data;
}

export async function getFollowingUsers() {
  const response: AxiosResponse<UserFollowingViewModel[]> = await _axios.get(
    `/Follow/GetOwnFollowings`
  );

  return response.data;
}

//Discount Codes
export async function validateCouponForEvent(
  experienceId: number,
  userEmail: string,
  code: string,
  selectedTickets: number[]
) {
  const response: AxiosResponse<AppPromotionCodeViewModel> = await _axios.post(
    '/PromotionCodes/GetPromotionCode',
    {
      email: userEmail,
      gigId: experienceId,
      promotionCode: code,
      ticketIds: selectedTickets
    }
  );

  return response.data;
}

//Service area
export async function validateIfUserisWithinServiceArea(
  experienceId: number,
  coords: ICoordinates
) {
  const { lat, lng } = coords;
  const response: AxiosResponse = await _axios.get(
    `/Gigs/CheckIfIsInRange?gigId=${experienceId}&lat=${lat}&lng=${lng}`
  );

  return response.data;
}

export async function reportMediaFile(
  mediaFileId: number,
  reportReasonId: number,
  withSession: boolean = false
) {
  const response: AxiosResponse = await _axios.post(
    `/MediaFileReport/ReportVideo${
      !withSession ? 'NoAuth' : ''
    }?mediaFileId=${mediaFileId}&reportReasonId=${reportReasonId}`
  );

  return response.data;
}

export async function getBooking(bookingId: number) {
  const response: AxiosResponse<BookingViewModel> = await _axios.get(
    `/Bookings/${bookingId}`
  );

  return response.data;
}

export async function getQrCodeImageForBooking(bookingId: number) {
  const response: AxiosResponse<string> = await _axios.get(
    `/Qr/Booking?bookingId=${bookingId}`
  );

  return response.data;
}

export async function getQrCodeImageForBookingTickets(bookingId: number) {
  const response: AxiosResponse<BookingQrDataViewModel> = await _axios.get(
    '/Qr/Booking/QrData',
    {
      params: { bookingId }
    }
  );

  return response.data;
}

export async function getEventAttendeesData(
  experienceId: number,
  recurrenceId: number = 0
) {
  const response: AxiosResponse<BookingAttendeesResponse> = await _axios.get(
    '/Bookings/Attendances/GetAttendances',
    {
      params: {
        experienceId,
        recurrenceId
      }
    }
  );

  return response.data;
}

export async function getEventAttendeesDataForNoAuthor(
  experienceId: number,
  recurrenceId: number = 0
) {
  const response: AxiosResponse<BookingAttendeesResponse> = await _axios.get(
    '/Bookings/Attendances/GetAttendances/NoAuth',
    {
      params: {
        experienceId,
        recurrenceId
      }
    }
  );

  return response.data;
}

//TODO: remove this function and request BE to remove endpoint
export async function markAttendance(bookingId: number) {
  const response: AxiosResponse<string> = await _axios.get(
    `/Bookings/Attendances/ToggleAttendance/${bookingId}`
  );

  return response.data;
}

//TODO: remove this function and request BE to remove endpoint
export async function checkAttendance(bookingId: number) {
  const response: AxiosResponse<boolean> = await _axios.get(
    `/Bookings/Attendances/CheckBookingAttendance/${bookingId}`
  );

  return response.data;
}

export async function checkInTicketAttendance(
  code: string,
  eventId: number,
  ticketId: number,
  note?: string,
  updateFlag: boolean = false
) {
  const response: AxiosResponse<UserMinimumViewModel> = await _axios.get(
    '/Bookings/Attendances/Tickets/ToggleAttendance',
    { params: { code, eventId, ticketId, note, updateFlag } }
  );

  return response.data;
}

export async function updateAttendanceNotes(
  type: 'ticket' | 'qr',
  eventId: number,
  target: number | string,
  note?: string
) {
  const query = `${type === 'ticket' ? 'ticketId' : 'code'}`;
  const response: AxiosResponse = await _axios.put(
    '/Bookings/Attendances/Tickets/UpdateNotes',
    {
      eventId,
      [query]: target,
      note
    }
  );

  return response.data;
}

export async function getConvertEventPDF(bookingId: number) {
  const response: AxiosResponse<Blob> = await _axios.get(
    '/Payments/GetBookingConcertPdf',
    { params: { bookingId }, headers: { responseType: 'blob' } }
  );

  return response.data;
}

export async function getTicketPDF(bookingId: number, ticketId: number) {
  const response: AxiosResponse<Blob> = await _axios.get(
    '/Bookings/Tickets/GetTicketFile',
    {
      params: { bookingId, bookingTicketId: ticketId },
      headers: { 'content-type': 'application/pdf' },
      responseType: 'blob'
    }
  );

  return response.data;
}

//Create Events

export async function createEvent(data: FormData) {
  const response: AxiosResponse<GigViewModel> = await _axios.post(
    '/Gigs',
    data
  );

  return response.data;
}

export async function updateEvent(data: FormData) {
  const response: AxiosResponse<GigViewModel> = await _axios.put('/Gigs', data);

  return response.data;
}

export async function uploadEventVideo(data: FormData) {
  const response: AxiosResponse<GigViewModel> = await _axios.put(
    '/Gigs/UpdateGigVideo',
    data
  );

  return response.data;
}

export async function uploadEventImages(data: FormData) {
  const response: AxiosResponse<GigViewModel> = await _axios.post(
    '/Gigs/CreateUpdateGigImages',
    data,
    {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }
  );

  return response.data;
}

export async function deleteEvent(eventId: number) {
  const response: AxiosResponse = await _axios.delete(`/Gigs?id=${eventId}`);

  return response.data;
}

export async function cretaeDailyRecurrences(payload: DailyRecurrenceInput) {
  const response: AxiosResponse = await _axios.put(
    '/Recurrences/Recreate/Daily',
    payload
  );

  return response.data;
}

export async function cretaeWeeklyRecurrences(payload: WeeklyRecurrenceInput) {
  const response: AxiosResponse = await _axios.put(
    '/Recurrences/Recreate/Weekly',
    payload
  );

  return response.data;
}

export async function cretaeWeedayRecurrences(payload: WeekDayRecurrenceInput) {
  const response: AxiosResponse = await _axios.put(
    '/Recurrences/Recreate/Weekdays',
    payload
  );

  return response.data;
}

export async function cretaeMonthlyRecurrences(
  payload: MonthlyRecurrenceInput
) {
  const response: AxiosResponse = await _axios.put(
    '/Recurrences/Recreate/Monthly',
    payload
  );

  return response.data;
}

export async function cretaeMonthDayRecurrences(
  payload: MonthDayRecurrenceInput
) {
  const response: AxiosResponse = await _axios.put(
    '/Recurrences/Recreate/MonthDayOccurrence',
    payload
  );

  return response.data;
}

// User Meetings
export async function getUserVirtualMeetingsSettings() {
  const response: AxiosResponse<IntegratedMeeting[]> = await _axios.get(
    'UserVirtualMeeting/GetUserVirtualMeetingSettings'
  );

  return response.data;
}

export async function setUserVirtualMeetingtype(type: VirtualMeetingType) {
  const response: AxiosResponse = await _axios.post(
    `/UserVirtualMeeting/SetDefaultVirtualMeetingType?virtualMeetingType=${type}`
  );

  return response.data;
}

// Zoom Interface
export async function getZoomRedirectUrl() {
  const response: AxiosResponse<{ redirectUrl: string }> = await _axios.get(
    'Zoom/ZoomRedirectLink'
  );

  return response.data;
}

// Meet Interface
export async function getMeetRedirectUrl() {
  const response: AxiosResponse<{ redirectUrl: string }> = await _axios.get(
    'GoogleCalendar/GoogleCalendarRedirectLink'
  );

  return response.data;
}

//Timezones
export async function getTimezones() {
  const response: AxiosResponse<NodaResponse> = await _axios.get(
    'https://nodatime.org/TimeZones?version=2024a&format=json'
  );

  return response.data.zones;
}
