import { coupons } from './coupons';
import { discovery } from './discovery';
import { internal } from './internal';
import { products } from './products';

const api = {
  coupons,
  discovery,
  products,
  internal
};

export default api;
